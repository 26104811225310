import useSWR from 'swr';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useOrganization = orgId => {
  const url = REACT_APP_INSIGHTS_APP_BFF_API_URL + `/organizations/${orgId}`;

  const { data, error, isLoading, mutate } = useSWR(url, { shouldRetryOnError: false });

  return {
    organization: data,
    error,
    isLoading,
  };
};

export default useOrganization;
