import React, { useEffect, useState, useContext } from 'react';
import { Card, CardContent } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import { State } from '../State';

import useIncidents from '../hooks/useIncidents';
import useQuery from '../hooks/useQuery';

import IncidentsGrid from './revenueInsights/IncidentsGrid';
import PaginationControl from './revenueInsights/PaginationControl';
import TitleBar from './revenueInsights/TitleBar';
import InputBar from './revenueInsights/InputBar';

const ITEMS_PER_PAGE = 20;

function RevenueInsights() {
  const { updateState } = useContext(State);
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  // preserve the filtered count for pagination between data fetches
  const [filteredIncidentsCount, setFilteredIncidentsCount] = useState();

  // get url query params
  let page = query.get('page');
  page = page ? parseInt(page) : 1;
  let sensitivity = query.get('sensitivity');
  sensitivity ||= 'medium';

  // filters
  const handleChangePage = (_e, newPage) =>
    navigate(`${location.pathname}?page=${newPage + 1}&sensitivity=${sensitivity}`);
  const handleChangeSensitivity = (_e, newSensitivity) =>
    navigate(`${location.pathname}?sensitivity=${newSensitivity}`);

  // fetch incidents
  const { incidents, filteredCount, error, isLoading } = useIncidents(page, sensitivity);

  useEffect(() => {
    if (filteredCount >= 0) setFilteredIncidentsCount(filteredCount);
  });

  useEffect(() => {
    if (error) {
      console.log(error);
      updateState({ httpErrorNotifVisible: true });
    }
  }, [error]);

  const PaginationControlInstance = () => {
    const showPagination = isLoading || incidents?.length > 0;
    if (!showPagination) return;

    return (
      <PaginationControl
        count={filteredIncidentsCount}
        page={page - 1}
        handleChangePage={handleChangePage}
        disabled={isLoading}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    );
  };

  return (
    <Card variant="outlined" sx={{ width: 'inherit', borderWidth: { xs: 0, md: 1 } }}>
      <TitleBar />
      <InputBar
        sensitivity={sensitivity}
        handleChangeSensitivity={handleChangeSensitivity}
        setIncidentsCount={setFilteredIncidentsCount}
      />
      <CardContent sx={{ padding: '0 24px 24px 24px' }}>
        <PaginationControlInstance />
        <IncidentsGrid incidents={incidents} isLoading={isLoading} itemsPerPage={ITEMS_PER_PAGE} />
        <PaginationControlInstance />
      </CardContent>
    </Card>
  );
}

export default RevenueInsights;
