import React, { useEffect, useContext, useState } from 'react';
import { Card, CardContent, Grid, Box, CircularProgress } from '@mui/material';

import { State } from '../State';
import useQuery from '../hooks/useQuery';

import IncidentsGrid from './revenueInsights/IncidentsGrid';
import TitleBar from './revenueInsights/TitleBar';
import InputBar from './revenueInsights/InputBar';
import useSearch from '../hooks/useSearch';
import PaginationControl from './revenueInsights/PaginationControl';
import { useLocation, useNavigate } from 'react-router-dom';

const ITEMS_PER_PAGE = 20;

const cardSx = {
  width: 'inherit',
  borderWidth: { xs: 0, md: 1 },
  minHeight: { xs: 'calc(100vh - 60px)', md: 'calc(100vh - 68px)' },
};

function NoResults() {
  return (
    <Grid item xs={12}>
      <Box color="rgba(0, 0, 0, 0.60)">No results - refine your search query and try again</Box>
    </Grid>
  );
}

function LoadingTxt() {
  return (
    <Grid item xs={12}>
      <Box
        color="rgba(0, 0, 0, 0.60)"
        sx={{ m: '20px 0', display: 'flex', gap: '10px', alignItems: 'center' }}
      >
        <CircularProgress size="30px" />
        <Box sx={{ width: { xs: '100%' } }}>
          Your search is in progress! Our AI-powered engine is diligently analyzing data to deliver
          the best results to you promptly.
        </Box>
      </Box>
    </Grid>
  );
}

function RevenueInsightsSearch() {
  const { updateState } = useContext(State);
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();

  // preserve the filtered count for pagination between data fetches
  const [incidentsCount, setIncidentsCount] = useState();

  const userQuery = query.get('query');
  const page = query.get('page') ? parseInt(query.get('page')) : 1;

  const handleChangePage = (_e, newPage) =>
    navigate(`${location.pathname}?query=${userQuery}&page=${newPage + 1}`);

  const { incidents, totalCount, error, isLoading } = useSearch(userQuery, page);

  useEffect(() => {
    if (totalCount >= -1) setIncidentsCount(totalCount);
  });

  useEffect(() => {
    if (error) {
      console.log(error);
      updateState({ httpErrorNotifVisible: true });
    }
  }, [error]);

  const PaginationControlInstance = () => {
    const showPagination = isLoading || incidents?.length > 0;
    if (!showPagination) return;

    return (
      <PaginationControl
        count={incidentsCount}
        page={page - 1}
        handleChangePage={handleChangePage}
        disabled={isLoading}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    );
  };

  //const showPagination = incidents?.length > 0;

  return (
    <Card variant="outlined" sx={cardSx}>
      <TitleBar />
      <InputBar sensitivity={'medium'} setIncidentsCount={setIncidentsCount} />
      <CardContent sx={{ padding: '0 24px 24px 24px' }}>
        {incidents?.length === 0 && !isLoading && <NoResults />}
        {isLoading && <LoadingTxt />}
        <PaginationControlInstance />
        <IncidentsGrid incidents={incidents} isLoading={isLoading} />
        <PaginationControlInstance />
      </CardContent>
    </Card>
  );
}

export default RevenueInsightsSearch;
