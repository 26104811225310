import { useSWRConfig } from 'swr';

function useClearCache() {
  const { mutate } = useSWRConfig(); //get global mutate

  const clearCache = () =>
    mutate(
      key => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: false }, // do not revalidate
    );

  return clearCache;
}

export default useClearCache;
