import { useState } from 'react';
import { Badge, IconButton, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material';
import { SuccessNotif, ErrorNotif } from '../Notifications';
import useEventLog from '../../../../hooks/useEventLog';
import useUserInteractionCreate from '../../../../hooks/useUserInteractionCreate';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme, type }) => ({
  '& .MuiBadge-badge': {
    right: type == 'dislike' ? '-20px' : '1px',
    top: type == 'dislike' ? '11px' : '-11px',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    opacity: 0.8,
    fontSize: '0.55rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[100],
  },
}));

export default function SuggestionItem({
  incidentId,
  action,
  interactions,
  group,
  mutateInteractions,
}) {
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [showErrorNotif, setShowErrorNotif] = useState(false);

  const { logEvent: likeEvent, inProgress: likeProgress } = useEventLog(incidentId);
  const { logEvent: dislikeEvent, inProgress: dislikeProgress } = useEventLog(incidentId);
  const { trigger, inProgress } = useUserInteractionCreate(incidentId);

  // When remove the legacy like/dislikes, replace hadnleLike and handleDislike
  // with handleAction in the IconButton onClick
  const handleAction = async interaction => {
    setShowErrorNotif(false);
    setShowSuccessNotif(false);

    try {
      await trigger(
        { action: interaction, target_id: action.id, target_group: group },
        {},
        {
          onSuccess: (data, key, config) => {
            setShowSuccessNotif(true);
            mutateInteractions(data, {
              revalidate: false,
              populateCache: (data, interactions) => {
                return {
                  ...interactions,
                  ai_suggestions: {
                    ...interactions.ai_suggestions,
                    [group]: {
                      ...interactions.ai_suggestions[group],
                      [action.id]: data,
                    },
                  },
                };
              },
            });
          },
          onError: err => {
            setShowErrorNotif(true);
          },
        },
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleLike = async action => {
    handleAction('like');

    likeEvent({ action, actionValue: 'Like' });
  };

  const handleDislike = action => {
    handleAction('dislike');
    dislikeEvent({ action, actionValue: 'Dislike' });
  };

  return (
    <>
      <SuccessNotif
        showNotif={showSuccessNotif}
        handleCloseNotif={() => setShowSuccessNotif(false)}
      />
      <ErrorNotif showNotif={showErrorNotif} handleCloseNotif={() => setShowErrorNotif(false)} />

      <ListItem
        key={action.id}
        disablePadding
        sx={{ paddingRight: '78px' }}
        data-testid="suggestion-item"
      >
        <ListItemText primary={action.action} />
        <ListItemSecondaryAction
          data-testid="suggestion-actions"
          sx={{ zIndex: 900, opacity: 0.5 }}
        >
          <IconButton
            sx={{
              '> *': { fontSize: '1.1rem' },
              '.MuiSvgIcon-root': {
                opacity: interactions?.user_interaction == 'dislike' ? '0.5' : '1',
              },
            }}
            disabled={inProgress}
            aria-label="like"
            size="small"
            onClick={() => handleLike(action.action)}
            data-testid="like-button"
          >
            {interactions?.likes !== undefined && (
              <StyledBadge
                badgeContent={String(interactions.likes)}
                type="like"
                data-testid="like-badge"
              />
            )}
            <ThumbUpAltOutlined />
          </IconButton>

          <IconButton
            sx={{
              '> *': { fontSize: '1.1rem' },
              '.MuiSvgIcon-root': {
                opacity: interactions?.user_interaction == 'like' ? '0.5' : '1',
              },
            }}
            disabled={dislikeProgress}
            aria-label="dislike"
            size="small"
            onClick={() => handleDislike(action.action)}
            data-testid="dislike-button"
          >
            {interactions?.dislikes !== undefined && (
              <StyledBadge
                badgeContent={String(interactions.dislikes)}
                type="dislike"
                data-testid="dislike-badge"
              />
            )}
            <ThumbDownAltOutlined />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}
