import { Grid } from '@mui/material';
import { useState } from 'react';
import RICard from './RICard';
import RIModal from './RIModal';

function IncidentGridItem({ incident, openModal, index }) {
  return (
    <Grid item xs={12} sm={6} md={12} lg={6} xl={4} key={incident.id}>
      <RICard incident={incident} lv2OpenModal={openModal} index={index} />
    </Grid>
  );
}

function IncidentSkeletonGridItem() {
  return (
    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
      <RICard incident={null} />
    </Grid>
  );
}

function IncidentsGrid({ incidents, isLoading, itemsPerPage }) {
  // lv2 modal handling
  const [lv2IsOpen, lv2SetOpen] = useState(false);
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const selectedIncident = incidents?.find(inc => inc.id === selectedIncidentId);
  const openModal = incidentId => {
    setSelectedIncidentId(incidentId);
    lv2SetOpen(true);
  };
  const closeModal = () => {
    lv2SetOpen(false);
    setSelectedIncidentId(null);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      spacing={{ xs: 2, md: 3, lg: 3 }}
      alignItems="flex-start"
      data-testid="incidents-grid"
    >
      {selectedIncident && (
        <RIModal incident={selectedIncident} closeModal={closeModal} isOpen={lv2IsOpen} />
      )}
      {incidents && !isLoading
        ? incidents.map((inc, i) => (
            <IncidentGridItem incident={inc} openModal={openModal} index={i} key={i} />
          ))
        : [...Array(itemsPerPage || 20).keys()].map(i => <IncidentSkeletonGridItem key={i} />)}
    </Grid>
  );
}

export default IncidentsGrid;
