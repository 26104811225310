import { Grid } from '@mui/material';
import RICardTitle from '../riCard/RICardTitle';
import RICardTrend from '../riCard/RICardTrend';
import RIModalSection from '../riModal/RIModalSection';
import WaterfallChart from '../WaterfallChart';
import LimitsChart from '../LimitsChart';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

const headerSx = {
  display: 'flex',
  justifyContent: 'space-between',
};

const containerSx = { gap: '20px' };

const RIDetailsMain = ({ incident }) => {
  const {
    incidentTimeStamp,
    category,
    currency,
    revenueDelta,
    revenueExpectedDeviationPct,
    productViewsRevenueImpact,
    cartsRevenueImpact,
    checkoutsRevenueImpact,
    ordersRevenueImpact,
    aovRevenueImpact,
    revenueActual,
    lv2VisualSummary,
    timeSeries,
    baseline,
    anomalyDetectionMode,
    lv3IncidentDetails,
    lv3RootCauseAnalysisSummary,
    lv3RootCauseAnalysisDetails,
    funnelMetrics,
  } = incident;

  const waterfallChartValues = {
    baseline,
    productViewsRevenueImpact,
    cartsRevenueImpact,
    checkoutsRevenueImpact,
    ordersRevenueImpact,
    aovRevenueImpact,
    revenueActual,
  };

  // Sanitize and parse the HTML content for the specified elements
  const sanitizedIncidentDetails = parse(DOMPurify.sanitize(lv3IncidentDetails));
  const sanitizedRootCauseAnalysisSummary = parse(DOMPurify.sanitize(lv3RootCauseAnalysisSummary));
  const sanitizedRootCauseAnalysisDetails = parse(DOMPurify.sanitize(lv3RootCauseAnalysisDetails));

  return (
    <Grid data-testid="ri-details-main" container sx={containerSx}>
      <Grid item xs={12} sx={headerSx}>
        <RICardTitle date={incidentTimeStamp} title={category} />
        <RICardTrend
          currency={currency}
          revenueDelta={revenueDelta}
          revenueExpectedDeviationPct={revenueExpectedDeviationPct}
        />
      </Grid>

      <Grid item xs={12}>
        <RIModalSection title="Revenue Anomaly">{sanitizedIncidentDetails}</RIModalSection>
      </Grid>

      <Grid item xs={12}>
        <LimitsChart
          timeSeries={timeSeries}
          baseline={baseline}
          anomalyDetectionMode={anomalyDetectionMode}
          currency={currency}
        />
      </Grid>

      <Grid item xs={12}>
        <RIModalSection title="Root Cause Analysis">
          {sanitizedRootCauseAnalysisSummary}
        </RIModalSection>
      </Grid>

      <Grid item xs={12}>
        <RIModalSection>{sanitizedRootCauseAnalysisDetails}</RIModalSection>
      </Grid>

      <Grid item xs={12}>
        <WaterfallChart
          values={waterfallChartValues}
          title={lv2VisualSummary}
          currency={currency}
          width="100%"
          height="550px"
          funnelMetrics={funnelMetrics}
        />
      </Grid>
    </Grid>
  );
};

export default RIDetailsMain;
