import { formatDateRespectingTimezone } from './dateUtils';

const PLACEHOLDER = '<placeholder>';
const TSA = 'TSA';

function enricher(incident) {
  const { id, metadata, date, dimensions, status, assignedTo, keyMetric, incidentGraph } = incident;
  const enrichedMetadata = enrichMetadata(metadata, keyMetric);
  const incidentTimeStamp = formatDateRespectingTimezone(date);
  const category = getCategoryFromDimensions(dimensions);

  return {
    id,
    incidentTimeStamp,
    category,
    issueStatus: status,
    assignedResponsibility: assignedTo?.name,
    timeSeries: incidentGraph?.time_series,
    currency: keyMetric.symbol,
    ...enrichedMetadata,
    rawIncident: incident,
  };
}

/**
 * Enriches the metadata of an incident
 * @param {Object} metadata - The original metadata
 * @param {Object} keyMetric - The key metric of the incident
 * @returns {Object} - The enriched metadata
 */
function enrichMetadata(metadata, keyMetric) {
  const value = parseFloat(keyMetric.value);
  const lastValue = parseFloat(keyMetric.last_value);
  const hypothesesFallback = [
    {
      statement: '<placeholder>',
      description: '<placeholder>',
      analysis: '<placeholder>',
      result_structured: [],
      verdict: 'retained',
    },
    {
      statement: '<placeholder>',
      description: '<placeholder>',
      analysis: '<placeholder>',
      result: '<placeholder>',
      result_structured: [],
      verdict: 'retained',
    },
    {
      statement: '<placeholder>',
      description: '<placeholder>',
      analysis: '<placeholder>',
      result: '<placeholder>',
      result_structured: {
        lack_of_data: '<placeholder>',
        potential_internal_causes: '<placeholder>',
      },
      verdict: 'rejected',
    },
  ];

  return {
    revenueExpectedDeviationPct:
      typeof metadata?.revenueExpectedDeviationPct === 'number'
        ? metadata.revenueExpectedDeviationPct * 100
        : undefined,
    revenueDelta: metadata?.revenueDelta,
    baseline: metadata?.baseline,
    anomalyDetectionMode: metadata?.anomalyDetectionMode || TSA,

    lv1ExecutiveSummary: metadata?.lv1ExecutiveSummary || [PLACEHOLDER, PLACEHOLDER],

    lv2Overview: metadata?.lv2Overview || [PLACEHOLDER, PLACEHOLDER],
    lv2KeyInsights: metadata?.lv2KeyInsights || [PLACEHOLDER, PLACEHOLDER],
    lv2Actions: metadata?.lv2Actions
      ? Array.isArray(metadata.lv2Actions)
        ? metadata.lv2Actions
        : [metadata.lv2Actions]
      : [PLACEHOLDER],
    lv2VisualSummary: metadata?.lv2VisualSummary || PLACEHOLDER,

    revenuePrevious: isNaN(lastValue) ? 28713 : lastValue,
    productViewsRevenueImpact: metadata?.productViewsRevenueImpact,
    cartsRevenueImpact: metadata?.cartsRevenueImpact,
    checkoutsRevenueImpact: metadata?.checkoutsRevenueImpact,
    ordersRevenueImpact: metadata?.ordersRevenueImpact,
    aovRevenueImpact: metadata?.aovRevenueImpact,
    revenueActual: isNaN(value) ? 71603 : value,
    hypotheses: metadata?.hypotheses ?? hypothesesFallback,

    lv3IncidentDetails: metadata?.lv3IncidentDetails || PLACEHOLDER,
    lv3RootCauseAnalysisSummary: metadata?.lv3RootCauseAnalysisSummary || PLACEHOLDER,
    lv3RootCauseAnalysisDetails: metadata?.lv3RootCauseAnalysisDetails || PLACEHOLDER,
    lv3AISuggestions: metadata?.lv3AISuggestions || PLACEHOLDER,

    funnelMetrics: metadata?.metrics ?? [],

    actions: getActions(metadata?.actions),
  };
}

function getActions(actions) {
  if (Array.isArray(actions)) {
    return { ...actions[0], immediate: actions[0].immediate ?? actions[0].intermediate };
  }
  if (!actions) return getActionsFallback();
  return actions;
}

function getActionsFallback() {
  return {
    immediate: [
      { id: 1, action: PLACEHOLDER },
      { id: 2, action: PLACEHOLDER },
    ],
    long_term: [{ id: 1, action: PLACEHOLDER }],
  };
}

function getCategoryFromDimensions(dimensions) {
  return dimensions.find(d => d.name === 'product category')?.value || '';
}

export default enricher;
