import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { State } from '../State';

const { REACT_APP_PRISMATIC_LOGGING_URL } = process.env;

const fetcher = async (url, { arg: options = {} } = { arg: {} }) =>
  fetch(url, {
    ...options,
    method: 'post',
    headers: { ...options.headers, 'Content-Type': 'application/json' },
  }).then(async r => {
    if (!r.ok) {
      const errorBody = await r.text();
      throw new Error(errorBody || 'Network response was not ok');
    }
    return r.json();
  });

const useEventLog = incidentId => {
  const { trigger, isMutating, error } = useSWRMutation(
    [REACT_APP_PRISMATIC_LOGGING_URL],
    fetcher,
    {
      shouldRetryOnError: false,
    },
  );

  const location = useLocation();
  const { state } = useContext(State);
  const url = window.location.origin + location.pathname;

  const fixed_data = {
    organizationName: state.displayName,
    organizationId: parseInt(state.accountId),
    organizationUuid: state.accountUuid,
    userConnection: state.connection,
    userFriendlyName: state.name,
    userEmail: state.email,
    ticketId: incidentId,
    url,
  };

  const customTrigger = async data => {
    await trigger({ body: JSON.stringify({ ...fixed_data, ...data }) });
  };

  return { logEvent: customTrigger, inProgress: isMutating, error };
};

export default useEventLog;
