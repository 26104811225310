import React from 'react';
import { Box, Typography } from '@mui/material';

const titleBarStyle = {
  marginBottom: '24px',
  width: '100%',
  gap: '20px',
  borderBottom: '1px solid lightgray',
  display: 'flex',
  height: 'fit-content',
};

const titleStyle = {
  flexGrow: 1,
  margin: '24px',
  color: 'rgba(0,0,0,0.87)',
  fontSize: 20,
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: 0.15,
  display: { xs: 'none', sm: 'block' },
};

function TitleBar({}) {
  return (
    <Box sx={titleBarStyle} data-testid="title-bar">
      <Typography variant="h5" sx={titleStyle}>
        Revenue Insights
      </Typography>
    </Box>
  );
}

export default TitleBar;
