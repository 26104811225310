import React from 'react';
import { Box, Card, CardContent, CardActions, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';

import RICardTitle from './riCard/RICardTitle';
import RICardSkeleton from './riCard/RICardSkeleton';
import RICardTrend from './riCard/RICardTrend';

const summaryStyle = {
  color: 'gray',
  minHeight: '115px',
};

const cardSx = { p: 1 };

const cardContentSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'flex-start',
};

export default function RICard({ incident, lv2OpenModal, index }) {
  if (!incident) return <RICardSkeleton />;

  const {
    revenueDelta,
    incidentTimeStamp,
    category,
    revenueExpectedDeviationPct,
    lv1ExecutiveSummary,
    currency,
    id,
  } = incident;

  return (
    <Card variant="outlined" sx={cardSx}>
      <CardContent sx={cardContentSx}>
        <RICardTitle date={incidentTimeStamp} title={category} index={index} />
        <RICardTrend
          revenueDelta={revenueDelta}
          currency={currency}
          revenueExpectedDeviationPct={revenueExpectedDeviationPct}
        />
        <Box sx={summaryStyle}>
          {
            <ul>
              {lv1ExecutiveSummary.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
          }
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          variant="text"
          component={NavLink}
          to={`/revenue-insights/${id}`}
          state={{ incident }}
          size="small"
        >
          MORE
        </Button>
      </CardActions>
    </Card>
  );
}
