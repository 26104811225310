import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { FormLabel } from '@mui/material';

function MetadataForm({ connector, connector_metadata, onChange }) {
  const schema = connector?.config;
  const formData = connector_metadata;

  function TitleFieldTemplate(props) {
    return <FormLabel component="legend">Connector Metadata</FormLabel>;
  }

  const uiSchema = {
    'ui:rootFieldId': '',
    'ui:submitButtonOptions': { norender: true },
    'ui:TitleFieldTemplate': TitleFieldTemplate,
  };

  return (
    schema && (
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={onChange}
        showErrorList={false}
        validator={validator}
        liveValidate
        liveOmit={true}
        omitExtraData={true}
      />
    )
  );
}

export default MetadataForm;
