import useMutate from './useMutate';

const { REACT_APP_CUI_GRAPE_API_URL } = process.env;

const useMetadataPatch = incidentId => {
  const url = `${REACT_APP_CUI_GRAPE_API_URL}/incidents/${incidentId}/metadata`;

  const { trigger, inProgress, error } = useMutate(url, 'PATCH');

  return { trigger, inProgress, error };
};

export default useMetadataPatch;
