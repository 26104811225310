import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from '@mui/material';
import useIntegrationCreate from '../../hooks/useIntegrationCreate';
import IntegrationForm from './IntegrationForm';

function CreateIntegrationDialog({ integration, isOpen, closeDialog, mutateFlowInstance }) {
  const { connectors } = integration;
  const { flowInstanceId } = useParams();

  const [metadata, setMetadata] = useState();
  const [errorMetadata, setErrorMetadata] = useState([]);
  const [connectorId, setConnectorId] = useState(connectors[0]?.id);
  const [errorResponse, setErrorResponse] = useState(false);

  const { trigger, inProgress, error } = useIntegrationCreate(flowInstanceId);

  const innerCloseDialog = () => {
    setErrorResponse(false);
    setMetadata([]);
    setConnectorId();
    closeDialog();
  };

  const handleMetadataChange = metadata => {
    setMetadata(metadata);
  };

  const handleConnectorChange = connector_id => {
    setConnectorId(connector_id);
  };

  const updateErrors = errors => {
    setErrorMetadata(errors);
  };

  const submitIntegration = () => {
    setErrorResponse(false);
    trigger({
      integration_id: integration.id,
      connector_id: connectorId,
      connector_metadata: metadata,
    })
      .then(() => {
        mutateFlowInstance();
        innerCloseDialog();
      })
      .catch(e => {
        setErrorResponse(true);
        console.error(e);
      });
  };

  return (
    <Dialog open={isOpen} onClose={innerCloseDialog}>
      <DialogTitle>{integration.name}</DialogTitle>
      <DialogContent>
        {errorResponse && <Alert severity="error">Whoops! An error occured.</Alert>}

        <IntegrationForm
          connectors={connectors}
          updateConnector={handleConnectorChange}
          updateMetadata={handleMetadataChange}
          updateErrors={updateErrors}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={innerCloseDialog}>Cancel</Button>
        <Button onClick={submitIntegration} disabled={inProgress || errorMetadata.length > 0}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateIntegrationDialog;
