import { Box, CardHeader, CardContent, Typography, List, Alert } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Widget from './Widget';
import SuggestionItem from './AISuggestionsWidget/SuggestionItem';
import useUserInteractions from '../../../hooks/useUserInteractions';
import { ComingSoonOverlay } from '../common/ComingSoon';

const cardHeaderStyles = {
  pb: 0,
  '& .MuiCardHeader-title': {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: '700',
    textAlign: 'center',
  },
};

const cardContentSx = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
};

const subtitleStyles = {
  fontWeight: 'bold',
  mt: 2,
  textAlign: 'left',
};

const actionsBoxSx = {
  display: 'flex',
  flexDirection: 'column',
};

const AlertStyles = {
  color: 'rgb(102, 102, 102)',
  borderColor: 'rgb(102, 102, 102)',
  '.MuiAlert-icon': { color: 'rgb(102, 102, 102)' },
};

export default function AISuggestionsWidget({ incidentId, lv3AiSuggestions, actions }) {
  const { interactions, mutate } = useUserInteractions(incidentId);

  return (
    <div style={{ position: 'relative' }}>
      <Widget data-testid="ai-suggestion-widget">
        <CardHeader
          title="AI SUGGESTIONS (BETA)"
          sx={cardHeaderStyles}
          data-testid="suggestion-title"
        />
        <CardContent sx={cardContentSx}>
          <Alert
            icon={<AutoAwesomeIcon fontSize="inherit" />}
            sx={AlertStyles}
            variant="outlined"
            data-testid="suggestion-alert"
          >
            This is a sneak peek! AI Suggestions appear after ~50 accurate AI explanations and
            recommendations.
          </Alert>
          <Box sx={actionsBoxSx}>
            <Typography variant="subtitle1" sx={subtitleStyles}>
              Immediate Actions
            </Typography>
            <List data-testid={`immediate-suggestions`}>
              {actions.immediate?.map(action => (
                <SuggestionItem
                  key={action.id}
                  action={action}
                  incidentId={incidentId}
                  interactions={interactions?.ai_suggestions.immediate?.[action.id]}
                  mutateInteractions={mutate}
                  group="immediate"
                />
              ))}
            </List>
          </Box>
          <Box sx={actionsBoxSx}>
            <Typography variant="subtitle1" sx={subtitleStyles}>
              Longer-Term Solutions
            </Typography>
            <List data-testid={`longer-term-suggestions`}>
              {actions.long_term?.map(action => (
                <SuggestionItem
                  key={action.id}
                  action={action}
                  incidentId={incidentId}
                  interactions={interactions?.ai_suggestions.long_term?.[action.id]}
                  mutateInteractions={mutate}
                  group="long_term"
                />
              ))}
            </List>
          </Box>
        </CardContent>
      </Widget>
      <ComingSoonOverlay cursor={true} />
    </div>
  );
}
