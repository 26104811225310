import { Routes, Route, Navigate } from 'react-router-dom';

import RevenueInsights from '../components/RevenueInsights';
import ProtectedRoute from '../components/ProtectedRoute';
import RIDetails from '../components/revenueInsights/RIDetails';
import UserList from '../components/UserList';
import Login from '../components/Login';
import RevenueInsightsSearch from '../components/RevenueInsightsSearch';
import Integrations from '../components/Integrations';

const Routing = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="" element={<Navigate to="/revenue-insights" />} />
      <Route path="/" element={<Navigate to="/revenue-insights" />} />
      <Route
        path="/revenue-insights"
        element={
          <ProtectedRoute roles="USER">
            <RevenueInsights />
          </ProtectedRoute>
        }
      />
      <Route
        path="/revenue-insights/:incidentId"
        element={
          <ProtectedRoute roles="USER">
            <RIDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/revenue-insights/search"
        element={
          <ProtectedRoute roles="USER">
            <RevenueInsightsSearch />
          </ProtectedRoute>
        }
      />
      <Route
        path="/integrations/:flowInstanceId"
        element={
          <ProtectedRoute roles="USER">
            <Integrations />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute roles="ADMIN">
            <UserList />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Routing;
