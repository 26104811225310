import { useContext } from 'react';
import useSWR from 'swr';

import { State } from '../State';
import enricher from '../utils/enricher';

const { REACT_APP_CUI_GRAPE_API_URL } = process.env;

function urlParamsStr(page, sensitivity) {
  let urlParams = [];
  urlParams.push(`page=${page}`);
  urlParams.push(`filters[visible][]=true`);

  let sensitivityFilter;
  if (sensitivity === 'low') sensitivityFilter = 'filters[sensitivity][]=low';
  if (sensitivity === 'medium')
    sensitivityFilter = 'filters[sensitivity][]=low&filters[sensitivity][]=medium';
  if (sensitivityFilter) urlParams.push(sensitivityFilter);

  return '?' + urlParams.join('&');
}

const useIncidents = (page, sensitivity) => {
  const { state } = useContext(State);

  const url =
    '' +
    REACT_APP_CUI_GRAPE_API_URL +
    `/accounts/${state.accountId}/detailed_incidents` +
    urlParamsStr(page, sensitivity);

  const { data, error, isLoading } = useSWR(url, { shouldRetryOnError: false });

  return {
    incidents: data?.incidents?.map(enricher),
    filteredCount: data?.filteredCount,
    error,
    isLoading,
  };
};

export default useIncidents;
