import { Snackbar, Alert } from '@mui/material';

function GlobalNotification({ showNotif, handleCloseNotif, severity, message }) {
  return (
    <Snackbar
      open={showNotif}
      onClose={handleCloseNotif}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={6000}
    >
      <Alert
        onClose={handleCloseNotif}
        elevation={6}
        variant="filled"
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default GlobalNotification;
