import { useEffect, useRef, useState } from 'react';
import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Create, Send } from '@mui/icons-material';

import useEventLog from '../../../hooks/useEventLog';
import { ErrorNotif, SuccessNotif } from './Notifications';
import { escapeChars } from '../../../utils/sanitize';
import Widget from './Widget';
import VideoModal from './Hypotheses/VideoModal';
import HypothesesModal from './Hypotheses/HypothesesModal';
import VerdictIcon from './Hypotheses/VerdictIcon';

const cardHeaderStyles = {
  pb: 0,
  '& .MuiCardHeader-title': {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: '700',
    textAlign: 'center',
  },
};

const cardContentSx = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
};

const iconButtonSx = {
  position: 'relative',
  top: 0,
  right: 0,
  color: 'text.secondary',
};

const iconSx = {
  minWidth: 'auto',
  marginRight: 1,
};

const listItemSx = {
  display: 'flex',
  alignItems: 'center',
  p: 0,
  gap: '8px',
  cursor: 'pointer',
};

const titleSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const subtitleSx = {
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.50px',
};

export default function HypothesesTestedWidget({ incidentId, hypotheses }) {
  const sortedHypotheses = hypotheses?.sort((a, b) => {
    const order = { retained: 1, rejected: 2, inconclusive: 3, skipped: 4, untested: 5 };
    return order[a.verdict] - order[b.verdict];
  });

  const {
    logEvent: logNewHypothesis,
    inProgress: newHypothesisInProgress,
    error: newHypothesisError,
  } = useEventLog(incidentId);
  const { logEvent } = useEventLog(incidentId);

  const [isHypothesesModalOpen, setIsHypothesesModalOpen] = useState(false);
  const [selectedHypothesisIndex, setSelectedHypothesisIndex] = useState(null);

  const handleHypothesisClick = (index, hypothesis) => {
    setSelectedHypothesisIndex(index);
    setIsHypothesesModalOpen(true);
    logEvent({ action: 'Hypotheses tested click', actionValue: hypothesis?.statement });
  };

  const handleHypothesesModalClose = () => {
    setIsHypothesesModalOpen(false);
  };

  const [newHypothesis, setNewHypothesis] = useState('');
  const newHypothesisInProgressPrevious = useRef(newHypothesisInProgress);

  const handleNewHypothesisChange = event => setNewHypothesis(event.target.value);

  const handleNewHypothesisSubmit = () => {
    if (newHypothesis.trim() === '') return;
    logNewHypothesis({
      action: 'Hypotheses tested feedback submission',
      actionValue: escapeChars(newHypothesis),
    });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleNewHypothesisSubmit();
      event.preventDefault();
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoSrc = useRef('');

  const handleLinkClick = event => {
    const target = event.target.closest('a[data-sra-url]');
    if (target) {
      event.preventDefault();
      videoSrc.current = target.dataset.sraUrl;
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleLinkClick);
    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, [handleLinkClick]);

  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [showErrorNotif, setShowErrorNotif] = useState(false);

  useEffect(() => {
    if (newHypothesisInProgressPrevious.current === true && newHypothesisInProgress === false) {
      if (!newHypothesisError) {
        setNewHypothesis('');
        setShowSuccessNotif(true);
      } else setShowErrorNotif(true);
    }
    newHypothesisInProgressPrevious.current = newHypothesisInProgress;
  }, [newHypothesisInProgress]);

  return (
    <Widget data-testid="hypotheses-tested-widget">
      <SuccessNotif
        showNotif={showSuccessNotif}
        handleCloseNotif={() => setShowSuccessNotif(false)}
      />
      <ErrorNotif showNotif={showErrorNotif} handleCloseNotif={() => setShowErrorNotif(false)} />
      <CardHeader
        title={
          <Box sx={titleSx}>
            <Box>HYPOTHESES TESTED</Box>
          </Box>
        }
        sx={cardHeaderStyles}
        action={<IconButton aria-label="info" size="small" sx={iconButtonSx} />}
      />
      <CardContent sx={cardContentSx}>
        <Typography variant="body2" color="text.secondary" sx={subtitleSx}>
          Click on each hypothesis to see more details:
        </Typography>
        <List
          sx={{
            listStyle: 'none',
            p: 0,
            m: 0,
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
          }}
        >
          {sortedHypotheses?.map((hypothesis, index) => (
            <ListItem
              key={index}
              sx={listItemSx}
              onClick={() => handleHypothesisClick(index, hypothesis)}
              data-testid={`hypothesis-item-${index}`}
            >
              <ListItemIcon sx={iconSx}>
                <VerdictIcon verdict={hypothesis.verdict} />
              </ListItemIcon>
              <ListItemText primary={hypothesis?.statement} />
            </ListItem>
          ))}
        </List>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          size="small"
          id="question"
          placeholder="Add hypotheses for our AI agent"
          variant="outlined"
          sx={{ mt: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Create />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="send"
                  onClick={handleNewHypothesisSubmit}
                  disabled={newHypothesisInProgress}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
            style: { overflow: 'auto' },
          }}
          onKeyDown={handleKeyDown}
          value={newHypothesis}
          onChange={handleNewHypothesisChange}
          disabled={newHypothesisInProgress}
          inputProps={{ maxLength: 1000, style: { overflow: 'auto' } }}
        />
      </CardContent>
      {videoSrc.current && (
        <VideoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          videoSrc={videoSrc.current}
        />
      )}
      <HypothesesModal
        hypotheses={hypotheses}
        clickedHypothesisIndex={selectedHypothesisIndex}
        isOpen={isHypothesesModalOpen}
        handleClose={handleHypothesesModalClose}
      />
    </Widget>
  );
}
