import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * Formats a date string to 'MMMM D, YYYY' format. It checks if the date is in UTC format
 * (ends with 'Z'). If not, it attempts to use the provided UTC offset in the date string.
 * @param {string} date - The date string to format.
 * @returns {string} The formatted date string.
 */
export function formatDateRespectingTimezone(date) {
  // example UTC Time 2024-04-02T21:50:00.000Z
  const isUTC = date.toString().endsWith('Z');

  // example Tokyo Time (Japan Standard Time, UTC+9)
  // 2024-04-03T06:50:00.000+09:00
  const originalTimezone = date.toString().slice(-6);

  const utcOffset = isUTC ? 0 : originalTimezone;
  return dayjs(date).utcOffset(utcOffset).format('MMMM D, YYYY'); // THE FIX
}
