function Fetcher(accessToken) {
  const authHeader = { Authorization: `Bearer ${accessToken}` };

  const fetcher = async (url, { arg: options = {} } = { arg: {} }) => {
    const response = await fetch(url, {
      ...options,
      headers: { ...options.headers, ...authHeader },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  return fetcher;
}

export default Fetcher;
