import React, { useEffect, useContext } from 'react';
import { Box, Drawer } from '@mui/material';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

import { State } from '../State';
import Sidebar from './Sidebar';
import Menubar from './Menubar';
import Routing from './Routing';
import HttpErrorNotif from './HttpErrorNotif';

const { REACT_APP_ORG_METADATA_NAMESPACE } = process.env;
const claimKey = key => REACT_APP_ORG_METADATA_NAMESPACE + '/' + key;

const mainFrameSx = {
  m: '33px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  '@media (max-width: 900px)': {
    m: '64px 0 0 0',
  },
  '@media (max-width: 600px)': {
    m: '60px 0 0 0',
  },
};

function MainFrame({ children }) {
  return <Box sx={mainFrameSx}>{children}</Box>;
}

const drawerSx = {
  '& .MuiDrawer-paper': { width: 256 },
  display: { xs: 'none', md: 'block' },
  width: 256,
};

function Home() {
  const { isAuthenticated, logout, getIdTokenClaims } = useAuth0();
  const { state, updateState } = useContext(State);

  useEffect(() => {
    if (!isAuthenticated) return;

    getIdTokenClaims().then(
      ({
        ['org_id']: auth0OrgID,
        [claimKey('accountId')]: accountId,
        [claimKey('displayName')]: displayName,
        [claimKey('email')]: email,
        [claimKey('connection')]: connection,
        [claimKey('accountUuid')]: accountUuid,
        [claimKey('name')]: name,
      }) => {
        if (!accountId) logout({ logoutParams: { returnTo: window.location.origin } });
        updateState({ accountId, auth0OrgID, displayName, email, connection, accountUuid, name });
      },
    );
  }, [isAuthenticated]);

  return (
    state.accountId && (
      <>
        <Menubar />
        <Drawer variant="permanent" sx={drawerSx}>
          <Sidebar />
        </Drawer>
        <MainFrame>
          <Routing />
        </MainFrame>
        <HttpErrorNotif />
      </>
    )
  );
}

export default withAuthenticationRequired(Home);
