import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ExpandMore } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import * as Icons from '@mui/icons-material';
import useFlowInstance from '../hooks/useFlowInstance';
import Title from './integrations/Title';
import TitleBar from './integrations/TitleBar';
import FlowIntegrationCard from './integrations/FlowIntegrationCard';
import FlowInstanceIntegrationCard from './integrations/FlowInstanceIntegrationCard';
import { useParams } from 'react-router-dom';
import useRoleCheck from '../hooks/useRoleCheck';

const listItemSx = {
  '& [aria-label="expand-arrow"]': {
    display: 'none',
  },
  '&:hover [aria-label="expand-arrow"]': {
    display: 'inline-flex',
  },
  '& .Mui-selected [aria-label="expand-arrow"]': {
    display: 'inline-flex',
  },
  '&:hover [aria-label="activated-icon"]': {
    display: 'none',
  },
  '& .Mui-selected [aria-label="activated-icon"]': {
    display: 'none',
  },
};

export default () => {
  const { flowInstanceId } = useParams();

  const { flowInstance, error, isLoading, mutate } = useFlowInstance(flowInstanceId);

  const [selectedIntegration, setSelectedIntegration] = useState();

  const isAdmin = useRoleCheck('ADMIN');

  useEffect(() => {
    setSelectedIntegration();
  }, flowInstanceId);

  const clickItem = integration => {
    setSelectedIntegration(integration);
  };

  const Icon = iconName => {
    const Icon = Icons[iconName];
    return <Icon />;
  };

  const IntegrationCard = flowIntegration => {
    const flowInstanceIntegration =
      flowInstance?.mapped_flow_instance_integrations[flowIntegration.id];
    return flowInstanceIntegration?.status == 'active' || (flowInstanceIntegration && isAdmin) ? (
      <FlowInstanceIntegrationCard
        flowInstanceIntegration={flowInstance.mapped_flow_instance_integrations[flowIntegration.id]}
        integration={flowIntegration}
        mutateFlowInstance={mutate}
      />
    ) : (
      <FlowIntegrationCard integration={flowIntegration} mutateFlowInstance={mutate} />
    );
  };

  return (
    <Card variant="outlined" sx={{ width: 'inherit', borderWidth: { xs: 0, md: 1 } }}>
      <TitleBar>
        <Title variant="h5">Integrations</Title>
      </TitleBar>
      <CardContent sx={{ padding: '0 24px 24px 24px' }}>
        {/** desktop version */}
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'row', gap: '30px' }}>
          <Box sx={{ width: { sm: '45%', md: '45%', lg: '35%', xl: '25%' }, flexShrink: 0 }}>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography>Error loading integrations</Typography>}
            {!isLoading && !error && flowInstance && (
              <Box sx={{ width: '100%' }}>
                <List sx={{ '& .MuiListItem-padding': { p: 0 } }}>
                  {Object.entries(flowInstance.grouped_flow_integrations).map(
                    ([group, integrations]) => (
                      <Box key={group}>
                        <ListSubheader key={group}>{group}</ListSubheader>
                        {integrations.map(flowIntegration => (
                          <ListItem
                            sx={listItemSx}
                            key={flowIntegration.id}
                            onClick={() => clickItem(flowIntegration)}
                          >
                            <ListItemButton
                              selected={selectedIntegration?.id === flowIntegration.id}
                            >
                              <ListItemIcon>{Icon(flowIntegration.icon)}</ListItemIcon>
                              <ListItemText primary={flowIntegration.name} />
                              {flowInstance.mapped_flow_instance_integrations[flowIntegration.id]
                                ?.status == 'active' && (
                                <ListItemIcon
                                  sx={{ justifyContent: 'flex-end', minWidth: '40px' }}
                                  edge="end"
                                  aria-label="activated-icon"
                                >
                                  <CheckCircleOutlineIcon />
                                </ListItemIcon>
                              )}
                              <ListItemIcon
                                sx={{ justifyContent: 'flex-end', minWidth: '40px' }}
                                edge="end"
                                aria-label="expand-arrow"
                              >
                                <KeyboardArrowRightIcon />
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </Box>
                    ),
                  )}
                </List>
              </Box>
            )}
          </Box>
          <Box sx={{ alignSelf: 'flex-start', flexGrow: 1, maxWidth: '420px' }}>
            {selectedIntegration && IntegrationCard(selectedIntegration)}
          </Box>
        </Box>

        {/** mobile version */}
        <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', gap: '20px' }}>
          {flowInstance &&
            Object.entries(flowInstance.grouped_flow_integrations).map(([group, integrations]) => (
              <Box key={group}>
                <Typography variant="h6" key={group} mb="15px">
                  {group}
                </Typography>
                {integrations.map(i => (
                  <Accordion key={i.id}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <ListItemIcon>{Icon(i.icon)}</ListItemIcon>
                      <ListItemText primary={i.name} />
                    </AccordionSummary>
                    <AccordionDetails>{IntegrationCard(i)}</AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};
