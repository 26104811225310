import React from 'react';
import { Box, Typography } from '@mui/material';

const RICardTitle = ({ date, title, index }) => {
  const dateStyle = {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
    lineHeight: '12px',
    letterSpacing: 1.5,
  };

  const titleStyle = {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: 0.15,
    wordBreak: 'break-all',
  };

  return (
    <Box data-testid={`incident-dimension-${index}`}>
      <Typography sx={dateStyle} gutterBottom>
        {date}
      </Typography>
      <Typography sx={titleStyle}>{title}</Typography>
    </Box>
  );
};

export default RICardTitle;
