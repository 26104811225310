import { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Tooltip,
  Button,
  Typography,
} from '@mui/material';
import CreateIntegrationDialog from './CreateIntegartionDialog';
import useRoleCheck from '../../hooks/useRoleCheck';

const iconSx = {
  height: '30px',
  width: '30px',
  objectFit: 'contain',
};

const FlowIntegrationCard = ({ integration, mutateFlowInstance }) => {
  const isAdmin = useRoleCheck('ADMIN');
  const enabledActions = isAdmin;
  const [integrationDialogOpen, setIntegrationDialogOpen] = useState(false);

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', borderWidth: 1 }}>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {integration.description}
          </Typography>
          {integration.connectors.length > 0 && (
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Available connectors:
                </ListSubheader>
              }
            >
              {integration.connectors.map(connector => (
                <ListItem key={connector.id}>
                  <ListItemIcon>
                    <Box component="img" sx={iconSx} src={connector.icon} />
                  </ListItemIcon>
                  <ListItemText primary={connector.name} />
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Tooltip
            arrow
            title={
              enabledActions
                ? 'Enable the integration'
                : 'You do not have the permissions to edit this integration. Contact us in case of any questions.'
            }
          >
            {/* Tooltip won't work for a disabled button that's wehy we wrap it in a span element */}
            <span>
              <Button
                size="small"
                disabled={!enabledActions}
                onClick={() => setIntegrationDialogOpen(true)}
              >
                Add
              </Button>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
      <CreateIntegrationDialog
        integration={integration}
        isOpen={integrationDialogOpen}
        closeDialog={() => setIntegrationDialogOpen(false)}
        mutateFlowInstance={mutateFlowInstance}
      />
    </>
  );
};

export default FlowIntegrationCard;
