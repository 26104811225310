import useSWR from 'swr';

import enricher from '../utils/enricher';

const { REACT_APP_CUI_GRAPE_API_URL } = process.env;

const useIncident = incidentId => {
  const url = '' + REACT_APP_CUI_GRAPE_API_URL + `/incidents/${incidentId}/details`;

  const { data, error, isLoading, mutate } = useSWR(url, { shouldRetryOnError: false });

  return {
    incident: data && enricher(data),
    error,
    isLoading,
    mutate,
  };
};

export default useIncident;
