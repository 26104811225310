import { useContext, useEffect, useState } from 'react';
import { Box, Paper, Grid, Snackbar, Alert, IconButton } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ModeEdit } from '@mui/icons-material';
import diff from 'microdiff';

import { State } from '../../State';
import useRoleCheck from '../../hooks/useRoleCheck';
import useIncident from '../../hooks/useIncident';
import useOrganization from '../../hooks/useOrganization';

import RIDetailsMain from './riDetails/RIDetailsMain';
import AISuggestionWidget from './riDetails/AISuggestionsWidget';
import HypothesesTestedWidget from './riDetails/HypothesesTestedWidget';
import ChatWithYourDataWidget from './riDetails/ChatWithYourDataWidget';
import EditIncidentModal from './riDetails/EditIncidentModal';
import RIDetailsSkeleton from './riDetails/RIDetailsSkeleton';

const leftSx = {
  padding: { xs: '25px', sm: '35px', md: '40px' },
  position: 'relative',
  borderWidth: {
    xs: 0,
    md: '1px',
  },
  borderRadius: {
    xs: 0,
    md: '4px',
  },
};

const editBtnSx = {
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '5px',
};

const LeftColumn = ({ incident, handleOpenModal }) => {
  const isAdmin = useRoleCheck('ADMIN');

  return (
    <Paper elevation={0} variant="outlined" sx={leftSx}>
      {isAdmin && (
        <IconButton sx={editBtnSx} onClick={handleOpenModal}>
          <ModeEdit data-testid="riDetails-edit" />
        </IconButton>
      )}
      <RIDetailsMain incident={incident} />
    </Paper>
  );
};

const rightSx = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '20px',
};

const RightColumn = ({ incident }) => {
  return (
    <Box sx={rightSx}>
      <AISuggestionWidget
        incidentId={incident.id}
        lv3AiSuggestions={incident.lv3AISuggestions}
        actions={incident.actions}
      />
      <HypothesesTestedWidget incidentId={incident.id} hypotheses={incident.hypotheses} />
      <ChatWithYourDataWidget incidentId={incident.id} />
    </Box>
  );
};

const InfoSnackbar = ({ showInfo, setShowInfo }) => {
  const handleClose = () => setShowInfo(false);

  return (
    <Snackbar
      open={showInfo}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
        Failed to fetch data from the server, displaying a cached version of the Insight.
      </Alert>
    </Snackbar>
  );
};

const WrongOrgAlert = ({ isOpen, currentOrgName, incidentAuth0Id }) => {
  const [showAlert, setShowAlert] = useState(isOpen);
  const { organization, error, isLoading } = useOrganization(incidentAuth0Id);
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();

  const switchOrg = () => {
    loginWithRedirect({ appState: { returnTo: location.pathname } });
  };

  return (
    !isLoading && (
      <Snackbar
        data-testid="wrong-org-alert"
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'middle' }}
      >
        <Alert onClose={() => setShowAlert(false)} severity="warning">
          {error ? (
            <>
              The insight you are trying to view doesn't belong to organization '{currentOrgName}
              '.&nbsp;
              <a onClick={switchOrg} style={{ cursor: 'pointer' }}>
                Switch to view
              </a>
              .
            </>
          ) : (
            <>
              The insight you are trying to view belongs to organization '
              {organization.display_name}'.&nbsp;
              <a onClick={switchOrg} style={{ cursor: 'pointer' }}>
                Switch to view
              </a>
              .
            </>
          )}
        </Alert>
      </Snackbar>
    )
  );
};

export default function RIDetails() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { state: authState, updateState } = useContext(State);
  let { incidentId } = useParams();

  const stateIncident = state?.incident;
  const { incident: fetchedIncident, error, mutate } = useIncident(incidentId);
  const incident = fetchedIncident || stateIncident;
  const [showInfo, setShowInfo] = useState(false);

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // always revalidate history state with fetched incident
  useEffect(() => {
    if (stateIncident && fetchedIncident && diff(stateIncident, fetchedIncident).length > 0) {
      // only update if there's a difference, otherwise infinite loop
      navigate(pathname, { state: { incident: fetchedIncident }, replace: true }); // hacky way to update history state
    }
  }, [stateIncident, fetchedIncident]);

  useEffect(() => {
    if (error) {
      console.error(error);
      if (incident) setShowInfo(true);
      else updateState({ httpErrorNotifVisible: true });
    }
  }, [error]);

  const [showEditModal, setShowEditModal] = useState(false);
  const handleOpenModal = () => setShowEditModal(true);
  const handleCloseModal = () => setShowEditModal(false);

  if (!incident) return <RIDetailsSkeleton />;

  if (incident.rawIncident.auth0OrgID !== authState.auth0OrgID) {
    return (
      <>
        <RIDetailsSkeleton />
        <WrongOrgAlert
          isOpen={true}
          currentOrgName={authState.displayName}
          incidentAuth0Id={incident.rawIncident.auth0OrgID}
        />
      </>
    );
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} lg={8}>
          <LeftColumn incident={incident} handleOpenModal={handleOpenModal} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RightColumn incident={incident} />
        </Grid>
      </Grid>
      <InfoSnackbar showInfo={showInfo} setShowInfo={setShowInfo} />
      {incident && (
        <EditIncidentModal
          incident={incident}
          isOpen={showEditModal}
          closeModal={handleCloseModal}
          mutateIncident={mutate}
        />
      )}
    </>
  );
}
