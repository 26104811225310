import useSWR from 'swr';
import enricher from '../utils/enricher';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useSearch = (query, page) => {
  const url = REACT_APP_INSIGHTS_APP_BFF_API_URL + `/search?q=${query}&page=${page}`;

  const { data, error, isLoading } = useSWR(url, { shouldRetryOnError: false });

  return {
    incidents: data?.incidents.map(enricher),
    totalCount: data?.totalCount,
    error,
    isLoading,
  };
};

export default useSearch;
